export const CHECKIN_ALLOWED_PROPERTIES_UPSELLING = [
	'64bfded8a08be970077c52be', //Airbnb Medellín
	'644976cac0699ba3b9ea96e9',
	'6542b865bda7611a46141ca7',
	'652779d1767e46ff7b578ae8',
	'654d43ff39b485419d9197c7',
	'64273d5f7531e4be62d1517a',
	'65f9cb07a0bd263024375199',
	'65fca5e7715782bbba94ff99',
	'65bbcac5d75bc39dc3c67fd1',
	'668c4081ef8ffe125d67ae7c',
	'668c546013f5ca5c9ec56df3',
	'66974bf2cfa74cf66d90ead0', // Airbnb stays
	'66ac1d3e04f2b7f14b559172', // CO Checkin - HostFully
	'6667311268f5338e697b36b6',  //fliphaus
	'65cfb17dbe0c6810b66f10f6', //Sea Life
];
